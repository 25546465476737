import { 
  BuildingStorefrontIcon, 
  CubeTransparentIcon, 
  CursorArrowRaysIcon, 
  LightBulbIcon,
  CheckIcon 
} from '@heroicons/react/24/outline'
import { Button } from '~/components'

const services = [
  {
    name: 'DTC',
    description:
      'Our services empower you to kick-off strategic projects with a well-defined and flexible framework that allows for iterative improvements and adjustments as needed.',
    href: '/service/dtc',
    features: [
      'Product marketing', 
      'Integrated marketing communications', 
      'Website & programs', 
      'Operation & planning', 
      'Logistics & fulfillment', 
      'Payment & risk-control'
    ],
    icon: BuildingStorefrontIcon,
  },
  {
    name: '+ Branding & Marketing',
    description:
      'With our comprehensive brand management solution, you can establish a strong and consistent brand identity by maintaining continuous communication with your target audience.',
    href: '/service/branding',
    features: [
      'Brand strategy', 
      'Creative direction',
      'Content strategy', 
      'Media planning', 
      'Manage touchpoints',
      'Foster brand loyalty', 
    ],
    icon: CursorArrowRaysIcon,
  },
  {
    name: '+ Omnichannel Expansion',
    description:
      "With our end-to-end solution, you can seamlessly manage all aspects of your commerce lifecycle and ensure your business is positioned for success in today's competitive market.",
    href: '/service/commerce',
    features: [
      'Pricing strategy', 
      'Omnichannel waterfall management', 
      'Regional go-to-market strategy', 
      'Commerce lifecycle P&L', 
      'Marketplace opeartion', 
      'Product localization'
    ],
    icon: CubeTransparentIcon,
  },
  {
    name: '+ Digital Transformation',
    description:
      "Having a strong digital foundation is essential to feeling confident in today's business landscape. It serves as a map to navigate future growth opportunities and stay ahead of the competition.",
    href: '/service/technology',
    features: [
      'Behavior tracking',
      'Performance tracking',
      'Transactions tracking',
      'Logistics tracking',
      'Customer data platform',
      'Business intelligence',
    ],
    icon: LightBulbIcon,
  },
]

export function Services() {
  return (
    <div className="bg-white">
      <div className="fl-container">
        <h2 className="fl-section-header">
          Work with us
        </h2>
        <div className="mt-12 grid grid-cols-1 gap-x-8 gap-y-20 sm:grid-cols-2 xl:mt-20 xl:grid-cols-4">
          {services.map((service) => (
            <div key={service.name} className="flex flex-col">
              <div className="flex-grow text-base font-semibold leading-7">
                <div className="flex h-10 w-10 items-center justify-center bg-secondary">
                  <service.icon className="h-6 w-6 text-primary" aria-hidden="true" />
                </div>
                <h3 className="mt-4 text-xl font-semibold group-hover:text-primary/40">
                  {service.name}
                </h3>
                <p className="mt-6 text-sm font-normal text-primary/80">
                  {service.description}
                </p>
              </div>
              <ul className="mt-8 space-y-2 text-sm leading-6 text-primary/80">
                {service.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
              <div className="relative mt-6 flex items-center gap-x-4">
                <Button to={service.href} mode='brutalist'>
                  Learn more
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}