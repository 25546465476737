import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { NotionText } from "~/routes/_content+/($lang)+/article.$id/components/NotionText";
import { PostObject } from "~/types/notion";

export function Articles({ posts } : { posts: PostObject[] }) {
  return (
    <section className="bg-bg1">
      <div className="fl-container">
        <h2 className="fl-section-header">
          Our thoughts
        </h2>
        {posts.length === 0 ? (
        <div className="mt-8 text-center m-10 text-primary/80">Pretty empty.</div>
        ) : (
        <div className="mt-8 grid gap-8 lg:grid-cols-2">
          {posts
            .filter((post) => post.properties.Published.checkbox)
            .map((post) => 
              <ArticleCard key={post.id} post={post}/>
            )
          }
        </div>
        )}
      </div>
    </section>
  )
}

function ArticleCard({ post } : { post : PostObject }) {
  const publishAt = post.properties.Date.date?.start || post.created_time;
  const author = (post.properties.Authors.people[0]);
  const tags = (post.properties.Tags?.multi_select || []);
  const link = `/article/${post.id}`
  const { Name: name, Description: description } = post.properties;
  const formattedDate = useMemo(
    () =>
      dayjs(new Date(publishAt)).format("MMM D, YYYY"),
    [publishAt]
  )
  return (
    <article className="p-6 flex flex-col fl-card-border bg-secondary shadow-smBrutal">
      <div className="flex justify-between items-center text-primary/80">
      {tags && tags.map((tag) => 
        <span key={tag.id} className="bg-contrast text-xs font-medium inline-flex items-center px-2.5 py-0.5">
          {tag.name}
        </span>
      )}
        <span className="text-sm">{formattedDate}</span>
      </div>
      <h2 className="mt-3 text-2xl font-semibold tracking-tight">
        <Link to={link}>
          <NotionText text={name.title} className="line-clamp-2"/>
        </Link>
      </h2>
      <p className="flex-grow mt-4 font-light">
        <NotionText text={description.rich_text} className="line-clamp-3"/>
      </p>
      <div className="mt-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          {author?.avatar_url ? 
            <img 
              className="w-7 h-7 rounded-full" 
              src={author.avatar_url} 
              alt="Author" /> :
            <UserCircleIcon className="w-7 h-7"/>
          }
          <span className="font-medium">
            {author?.name || "Anonymous"} 
          </span>
        </div>
        <Link to={link} className="inline-flex items-center font-medium hover:underline">
          Read more
          <svg className="ml-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
          </svg>
        </Link>
      </div>
    </article> 
  )
}

