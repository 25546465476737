export function Testimonial() {
    return (
      <section className="bg-primary">
        <div className="fl-container-margin text-contrast">
          <div className="mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="flex flex-col fl-container-padding lg:pr-8 xl:pr-20">
              <img className="h-12 self-start" src="https://cdn.shopifycdn.net/s/files/1/0580/4530/0912/files/Fastlane_-_-27_180x.jpg" alt="" />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8">
                  <p>
                    “作为国际化道路上深度合作伙伴，Fastlane 一直用专业为我们解决数字化转型问题，希望见证彼此的辉煌！”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  {/* <img
                    className="h-14 w-14 rounded-full bg-gray-800"
                    src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}
                  <div className="text-base">
                    <div className="font-semibold">Bing Du</div>
                    <div className="mt-1 text-contrast/60">CEO | RELX INTL</div>
                  </div>
                </figcaption>
              </figure>
            </div>
            <div className="flex flex-col border-t border-black fl-container-padding lg:border-l-2 lg:border-t-0 lg:pl-8 xl:pl-20">
              <img className="h-12 self-start" src="https://cdn.shopifycdn.net/s/files/1/0580/4530/0912/files/Fastlane_-_-25_a8dbb7ed-3da0-424f-b6b5-54094273a306_180x.jpg" alt="" />
              <figure className="mt-10 flex flex-auto flex-col justify-between">
                <blockquote className="text-lg leading-8">
                  <p>
                    “作为 Shopify Plus 的官方伙伴，Fastlane 已经在中国大陆取得领先，期待大家向全球领先迈进，咱们一起加油！”
                  </p>
                </blockquote>
                <figcaption className="mt-10 flex items-center gap-x-6">
                  {/* <img
                    className="h-14 w-14 rounded-full bg-gray-800"
                    src="https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}
                  <div className="text-base">
                    <div className="font-semibold">Andy</div>
                    <div className="mt-1 text-contrast/60">Senior Partner Manager | Shopify</div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    )
  }