/* eslint-disable hydrogen/prefer-image-component */
export function PartnerClients() {
  return (
    <section>
      <div className="fl-container ">
        <h2 className="fl-section-header">
          We work with innovative companies
        </h2>
        <div className="mt-6 grid grid-cols-2 gap-4 lg:gap-8 md:grid-cols-3 lg:mt-8">
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img 
              className="h-24 grayscale" 
              src="/img/clients/nothing.webp" 
              alt="NOTHING"
            />
          </div>
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img 
              className="max-h-20 grayscale"
              src="/img/clients/zte.webp"
              alt="ZTE"
            />
          </div>
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img
              className="max-h-28 grayscale"
              src="/img/clients/roborock.webp"
              alt="Roborock"
            />
          </div>
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img
              className="h-6 sm:h-8 grayscale"
              src="/img/clients/relx.svg"
              alt="RELX"
            />
          </div>
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img
              className="h-6 grayscale"
              src="/img/clients/xpeng.svg"
              alt="XPENG"
            />
          </div>
          <div className="col-span-1 h-32 flex justify-center items-center bg-contrast p-4 sm:p-6 fl-card-border">
            <img
              className="max-h-10 grayscale"
              src="/img/clients/midea.svg"
              alt="MIDEA"
            />
          </div>
          {/* <div className="col-span-1 h-32 flex justify-center items-center bg-contrast py-8 px-8 fl-card-border">
            <svg className="max-w-xs max-h-4">
                <use href="/img/clients/hasselblad.svg#Layer_1" stroke="grey"></use>
            </svg>
          </div> */}
        </div>
      </div>
    </section>
  )
}
