import clsx from 'clsx';
import { LinkButton } from '~/components';

export function Hero() {
  return (
    <div
      className={clsx(
        'relative flex flex-col items-center rounded-b-xl text-white bg-viva-magenta',
        'px-4 pt-24 pb-16 md:px-8 md:pt-32 md:pb-24 lg:pt-48 lg:pb-40',
      )}
    >
      {/* Title */}
      <h1
        className={clsx(
          'mb-7 max-w-[55rem] whitespace-pre-line text-center text-5xl leading-none',
          'md:text-7xl md:leading-none',
        )}
      >
        Accelerate the Globalization of Innovative Brands.
      </h1>

      {/* Link */}
      {/* <LinkButton link='/apps' className='text-viva-magenta bg-plein-air'>
        Apps
      </LinkButton> */}
    </div>
  );
}
